@value vars: "./vars.module.css";
@value c-darker-gray, c-black, c-teal, c-dark-teal, page-padding from vars;

.link {
  color: c-darker-gray;
}

.link:hover {
  color: c-black;
}

.link--accent {
  color: c-teal;
}

.link--accent:hover {
  color: c-dark-teal;
}

.buttonBase {
  color: white;
  font-size: 16px;
  text-align: center;
  background: c-teal;
  border: none;
  cursor: pointer;
}

.buttonBase:hover {
  background: c-dark-teal;
}

.button {
  composes: buttonBase;
  text-transform: uppercase;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.button--full {
  composes: buttonBase;
  width: 100%;
  line-height: 80px;
  display: inline-block;
  font-weight: bold;
}

.page {
  padding: page-padding;
  line-height: 1.5;
  max-width: 50ch;
  margin: 0 auto;
  position: relative;
}

.heading {
  margin: 20px 0 5px;
}

.heading:first-of-type {
  margin-top: 0;
}

.paragraph {
  margin-top: 0;
}

.backButton--top {
  composes: link--accent;
  position: absolute;
  /* stylelint-disable-next-line function-calc-no-unspaced-operator */
  top: calc(page-padding + 5px);
  right: page-padding;
}

.backButton--bottom {
  composes: button--full;
}

@media (min-width: 750px) {
  .backButton--bottom {
    display: none;
  }
}

/* Search result page */

.row {
  max-width: 750px;
  margin: auto;
  padding: 30px;
}
