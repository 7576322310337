@value vars: "./vars.module.css";
@value page-padding, c-gray, c-darker-gray, s-small, s-medium from vars;

.app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(./homeBackground.svg);
  background-size: 100% 50%;
  background-repeat: no-repeat;
}

.nav__item {
  position: absolute;
  top: page-padding;
  composes: link from "./shared.module.css";
}

.nav__offline {
  left: page-padding;
}

.nav__about {
  right: page-padding;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 50ch;
}

.main__title {
  margin: 0;
  margin-bottom: s-small;
}

@value main-hor-padding: 50px;

.main__subtitle {
  margin: 0;
  margin-bottom: s-medium;
  color: c-darker-gray;
  text-align: center;
  padding: 0 main-hor-padding;
}

.searchInput {
  /* stylelint-disable-next-line function-calc-no-unspaced-operator */
  width: calc(100% - main-hor-padding * 2);
  margin-bottom: s-medium;
  display: inline-block;
  box-sizing: border-box;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 5px;
}

.searchInput::placeholder {
  color: c-gray;
}

.buttons_container {
  display: flex;
  /* stylelint-disable-next-line function-calc-no-unspaced-operator */
  width: calc(100% - main-hor-padding * 2);
}

.button {
  composes: button from "./shared.module.css";
  line-height: 45px;
  height: 45px;
  font-weight: bold;
  padding: 0;
  flex: 1 0 0;
}

.button:first-child {
  margin-right: 5px;
}

.button:last-child {
  margin-left: 5px;
}

.background {
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
}

/* In portrait mode and height is too small (e.g. when mobile keyboard is visible */
@media screen and (orientation: landscape) and (max-height: 400px) {
  .nav__item,
  .main__title,
  .main__subtitle,
  .buttons_container {
    display: none;
  }

  .searchInput {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 0;
    padding: 15px;
  }
}
