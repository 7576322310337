@value vars: "./vars.module.css";
@value c-dark-gray from vars;

.searchResult {
  composes: row from "./shared.module.css";
}

.stats {
  font-size: 14px;
  color: c-dark-gray;
  margin: 0;
}

@value category-group-horizontal-padding: 30px;
@value category-group-vertical-padding: 25px;

.categoryGroup {
  margin-top: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  overflow: hidden;
  padding: category-group-vertical-padding category-group-horizontal-padding;
}

.categoryGroup:not(:last-child) {
  margin-bottom: 25px;
}

.categoryGroup__name {
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  text-transform: capitalize;
}

@value ing-list-grid-gap: 20px;

.categoryGroup__ingList {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 20px;
}

@value ingredient-card-horizontal-padding: 10px;
@value ingredient-card-vertical-padding: 10px;

.categoryGroup__ingListItem {
  display: inline-block;
  margin-bottom: 15px;
}

.categoryGroup__ingListItem:not(:last-child) {
  margin-right: 5px;
}

.ingredientPill {
  background: c-dark-gray;
  margin: 0;
  font-size: 14px;
  color: white;
  border-radius: 30px;
  display: inline;
  padding: 5px 15px;
  cursor: pointer;
}

/* MODAL */

.ingredient__name span {
  background: c-dark-gray;
  color: white;
  border-radius: 10px;
  font-size: 12px;
  padding: 3px 10px;
}

.ingredient__detail,
.ingredient__preDetail {
  padding:
    10px ingredient-card-horizontal-padding
    ingredient-card-vertical-padding;
  margin: 0; /* reset */
}

.ingredient__detail--empty {
  font-style: italic;
}

.ingredient__detail--list {
  padding-left: 24px;
}

.ingredient__synonym {
  margin: 0; /* reset */
}

.ingredient__synonymDetail {
  margin: 8px 0 15px;
}
