.AboutPage_categList__QFkQh {
  padding: 0 0 0 35px;
}

.AboutPage_categList__itemName__29frn {
  font-weight: bold;
}



.shared_link__2D9Gd {
  color: #6F6E70;
}

.shared_link__2D9Gd:hover {
  color: #000000;
}

.shared_link--accent__2sH46 {
  color: #00A9B3;
}

.shared_link--accent__2sH46:hover {
  color: #008B93;
}

.shared_buttonBase__WmJYS {
  color: white;
  font-size: 16px;
  text-align: center;
  background: #00A9B3;
  border: none;
  cursor: pointer;
}

.shared_buttonBase__WmJYS:hover {
  background: #008B93;
}

.shared_button__3gDr4 {
  text-transform: uppercase;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.shared_button--full__2RAyx {
  width: 100%;
  line-height: 80px;
  display: inline-block;
  font-weight: bold;
}

.shared_page__15DuP {
  padding: 40px;
  line-height: 1.5;
  max-width: 50ch;
  margin: 0 auto;
  position: relative;
}

.shared_heading__2CkdJ {
  margin: 20px 0 5px;
}

.shared_heading__2CkdJ:first-of-type {
  margin-top: 0;
}

.shared_paragraph__2NoEe {
  margin-top: 0;
}

.shared_backButton--top__1mPaL {
  position: absolute;
  /* stylelint-disable-next-line function-calc-no-unspaced-operator */
  top: calc(40px + 5px);
  right: 40px;
}

.shared_backButton--bottom__2yaH6 {
}

@media (min-width: 750px) {
  .shared_backButton--bottom__2yaH6 {
    display: none;
  }
}

/* Search result page */

.shared_row___VhQM {
  max-width: 750px;
  margin: auto;
  padding: 30px;
}

.PictureTaker_inputEle__3EWvE {
  /* hiding inspired by https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/ */
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.PictureTaker_button__1LP-w {
  width: 30px;
  height: 30px;
  cursor: pointer;
  padding: 5px;
}

.PictureTaker_button__1LP-w svg {
  fill: currentColor;
}

.PictureTaker_inputEle--isFocus__2JM3t + .PictureTaker_button__1LP-w,
.PictureTaker_inputEle__3EWvE:focus + .PictureTaker_button__1LP-w {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.SearchInput_searchInput__2-G5W {
  padding: 10px 15px;

  /* ellipsis */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.HomePage_app__2u_zu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(/static/media/homeBackground.73a521d3.svg);
  background-size: 100% 50%;
  background-repeat: no-repeat;
}

.HomePage_nav__item__3JPXS {
  position: absolute;
  top: 40px;
}

.HomePage_nav__offline__18yJ0 {
  left: 40px;
}

.HomePage_nav__about__3iQMr {
  right: 40px;
}

.HomePage_main__12c4t {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 50ch;
}

.HomePage_main__title__3ge33 {
  margin: 0;
  margin-bottom: 12px;
}

.HomePage_main__subtitle__3BF0D {
  margin: 0;
  margin-bottom: 24px;
  color: #6F6E70;
  text-align: center;
  padding: 0 50px;
}

.HomePage_searchInput__1cwsc {
  /* stylelint-disable-next-line function-calc-no-unspaced-operator */
  width: calc(100% - 50px * 2);
  margin-bottom: 24px;
  display: inline-block;
  box-sizing: border-box;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 5px;
}

.HomePage_searchInput__1cwsc::-ms-input-placeholder {
  color: #ADADAD;
}

.HomePage_searchInput__1cwsc::placeholder {
  color: #ADADAD;
}

.HomePage_buttons_container__VhPur {
  display: flex;
  /* stylelint-disable-next-line function-calc-no-unspaced-operator */
  width: calc(100% - 50px * 2);
}

.HomePage_button__ocPch {
  line-height: 45px;
  height: 45px;
  font-weight: bold;
  padding: 0;
  flex: 1 0;
}

.HomePage_button__ocPch:first-child {
  margin-right: 5px;
}

.HomePage_button__ocPch:last-child {
  margin-left: 5px;
}

.HomePage_background__pnuTi {
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
}

/* In portrait mode and height is too small (e.g. when mobile keyboard is visible */
@media screen and (orientation: landscape) and (max-height: 400px) {
  .HomePage_nav__item__3JPXS,
  .HomePage_main__title__3ge33,
  .HomePage_main__subtitle__3BF0D,
  .HomePage_buttons_container__VhPur {
    display: none;
  }

  .HomePage_searchInput__1cwsc {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 0;
    padding: 15px;
  }
}

.OCRProgress_container__2cEo- {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.OCRProgress_progress__IGfHy {
  font-size: 24px;
  font-weight: bold;
}

.OCRProgress_status__Zw1sU {
  margin-top: 5px;
  max-width: 20ch;
  text-align: center;
}

.OCRProgress_cancelButton__3sC7b {
  padding: 12px 20px;
  text-decoration: underline;
  cursor: pointer;
  position: absolute;
  bottom: 3%;
}

.OCRProgress_background__uVH33 {
  position: absolute;
  z-index: -1;
}

.OCRProgress_circle__2nsvF {
  border-radius: 50%;
  position: absolute;
  animation: OCRProgress_a-circle__1-0Ha 1s linear infinite;
}

.OCRProgress_circle--1__3CjW7 {
  width: 300px;
  height: 300px;
  background: #f9f9f9;
  transform-origin: 52% 51%;
}

.OCRProgress_circle--2__2UgwK {
  width: 250px;
  height: 250px;
  background: #f3f3f3;
  transform-origin: 51% 52%;
}

.OCRProgress_circle--3__3hrSz {
  width: 200px;
  height: 200px;
  background: #ededed;
  transform-origin: 53% 51%;
}

.OCRProgress_circle--4__15vrE {
  width: 150px;
  height: 150px;
  background: #e7e7e7;
  transform-origin: 51% 53%;
}

@keyframes OCRProgress_a-circle__1-0Ha {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.OfflinePage_container__1u1cL {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.OfflinePage_downloadButton__3G9SL {
  padding: 10px 20px;
  font-weight: bold;
  margin-top: 10px;
}

.OfflinePage_downloadButton__3G9SL:disabled {
  background: #ADADAD;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  cursor: not-allowed;
}

.OfflinePage_downloadButton__3G9SL:disabled:hover {
  background: #ADADAD;
}

.OfflinePage_backButton--bottom__lno13 {
  margin-top: auto;
}

/*
   Loading indicator
   from https://loading.io/css/
*/

.OfflinePage_lds-roller__3D0Ge {
  display: inline-block;
  position: relative;
  width: 15px;
  height: 15px;
  transform: scale(0.3);
  top: -5px;
  margin-left: 6px;
  margin-right: 8px;
}

.OfflinePage_lds-roller__3D0Ge div {
  animation: OfflinePage_lds-roller__3D0Ge 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}

.OfflinePage_lds-roller__3D0Ge div::after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  margin: -3px 0 0 -3px;
}

.OfflinePage_lds-roller__3D0Ge div:nth-child(1) {
  animation-delay: -0.036s;
}

.OfflinePage_lds-roller__3D0Ge div:nth-child(1)::after {
  top: 50px;
  left: 50px;
}

.OfflinePage_lds-roller__3D0Ge div:nth-child(2) {
  animation-delay: -0.072s;
}

.OfflinePage_lds-roller__3D0Ge div:nth-child(2)::after {
  top: 54px;
  left: 45px;
}

.OfflinePage_lds-roller__3D0Ge div:nth-child(3) {
  animation-delay: -0.108s;
}

.OfflinePage_lds-roller__3D0Ge div:nth-child(3)::after {
  top: 57px;
  left: 39px;
}

.OfflinePage_lds-roller__3D0Ge div:nth-child(4) {
  animation-delay: -0.144s;
}

.OfflinePage_lds-roller__3D0Ge div:nth-child(4)::after {
  top: 58px;
  left: 32px;
}

.OfflinePage_lds-roller__3D0Ge div:nth-child(5) {
  animation-delay: -0.18s;
}

.OfflinePage_lds-roller__3D0Ge div:nth-child(5)::after {
  top: 57px;
  left: 25px;
}

.OfflinePage_lds-roller__3D0Ge div:nth-child(6) {
  animation-delay: -0.216s;
}

.OfflinePage_lds-roller__3D0Ge div:nth-child(6)::after {
  top: 54px;
  left: 19px;
}

.OfflinePage_lds-roller__3D0Ge div:nth-child(7) {
  animation-delay: -0.252s;
}

.OfflinePage_lds-roller__3D0Ge div:nth-child(7)::after {
  top: 50px;
  left: 14px;
}

.OfflinePage_lds-roller__3D0Ge div:nth-child(8) {
  animation-delay: -0.288s;
}

.OfflinePage_lds-roller__3D0Ge div:nth-child(8)::after {
  top: 45px;
  left: 10px;
}

@keyframes OfflinePage_lds-roller__3D0Ge {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.Modal_backdrop__3X7yr {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal_modal__2SHrF {
  padding: 20px;
  background: white;
  border-radius: 5px;
  width: 85%;
  position: relative;
}

.Modal_closeButton__1vb5x {
  position: absolute;
  top: 20px;
  right: 20px;
  background: white;
  border: solid 1px gray;
  border-radius: 50%;
  font-size: 0;
  padding: 0;
  cursor: pointer;
}

.Modal_closeButton__1vb5x::before {
  content: "\2715";
  width: 40px;
  line-height: 40px;
  height: 40px;
  font-weight: bold;
  font-size: 18px;
  display: inline-block;
}

.SearchResult_searchResult__35drp {
}

.SearchResult_stats__20iUl {
  font-size: 14px;
  color: #9B9B9B;
  margin: 0;
}

.SearchResult_categoryGroup__31JYp {
  margin-top: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  overflow: hidden;
  padding: 25px 30px;
}

.SearchResult_categoryGroup__31JYp:not(:last-child) {
  margin-bottom: 25px;
}

.SearchResult_categoryGroup__name__e8oXf {
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  text-transform: capitalize;
}

.SearchResult_categoryGroup__ingList__bcgvr {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 20px;
}

.SearchResult_categoryGroup__ingListItem__2D_SQ {
  display: inline-block;
  margin-bottom: 15px;
}

.SearchResult_categoryGroup__ingListItem__2D_SQ:not(:last-child) {
  margin-right: 5px;
}

.SearchResult_ingredientPill__3gGih {
  background: #9B9B9B;
  margin: 0;
  font-size: 14px;
  color: white;
  border-radius: 30px;
  display: inline;
  padding: 5px 15px;
  cursor: pointer;
}

/* MODAL */

.SearchResult_ingredient__name__piGsa span {
  background: #9B9B9B;
  color: white;
  border-radius: 10px;
  font-size: 12px;
  padding: 3px 10px;
}

.SearchResult_ingredient__detail__3-0eD,
.SearchResult_ingredient__preDetail__1z-yz {
  padding:
    10px 10px
    10px;
  margin: 0; /* reset */
}

.SearchResult_ingredient__detail--empty__2S3_R {
  font-style: italic;
}

.SearchResult_ingredient__detail--list__xn38m {
  padding-left: 24px;
}

.SearchResult_ingredient__synonym__1bQjP {
  margin: 0; /* reset */
}

.SearchResult_ingredient__synonymDetail__3mILL {
  margin: 8px 0 15px;
}

.SearchResultPage_header__1E3gO {
  background: #E1E1E1;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  background: linear-gradient(90.76deg, #e1e1e1 0.11%, #f3f3f3);
}

.SearchResultPage_widthConstrainer__28mnc {
  display: flex;
  align-items: center;
}

.SearchResultPage_input__3jOhb {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: none;
  flex-grow: 1;
}

.SearchResultPage_pictureTaker__48Gjv {
  margin-left: 18px;
  color: white; /* for icon fill color */
  flex-shrink: 0;
  box-sizing: border-box;
  padding: 7px;
  width: 38px;
  height: 38px;
}

.SearchResultPage_backButton__1ertp {
  margin-left: 18px;
}

body {
  margin: 0;
  padding: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    "Courier New",
    monospace;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

