@value vars: "./vars.module.css";
@value c-light-gray, c-teal from vars;

.header {
  background: c-light-gray;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  background: linear-gradient(90.76deg, #e1e1e1 0.11%, #f3f3f3);
}

.widthConstrainer {
  composes: row from "./shared.module.css";
  display: flex;
  align-items: center;
}

.input {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: none;
  flex-grow: 1;
}

@value item-padding: 18px;

.pictureTaker {
  composes: button from "./shared.module.css";
  margin-left: item-padding;
  color: white; /* for icon fill color */
  flex-shrink: 0;
  box-sizing: border-box;
  padding: 7px;
  width: 38px;
  height: 38px;
}

.backButton {
  composes: link from "./shared.module.css";
  margin-left: item-padding;
}
